export default [
  'Ainslie FS',
  'Belconnen FS',
  'West Belconnen FS',
  'Chisholm FS',
  'Fyshwick FS',
  'South Tuggeranong FS',
  'Gungahlin FS',
  'Kambah FS',
  'Phillip FS',
  'Tuggeranong Depot',
  'Belconnen Depot',
  'Woden Depot',
  'Workshop',
  'HQ',
  'Rivers Station',
  'Molonglo Station',
  'Gungahlin Station',
  'Jerrabomberra Station',
  'Guises Creek Station',
  'Southern Station',
  'Tidbinbilla Station',
  'Hume Helibase',
  'Hume Training Centre',
  'PCS Depot',
  'Majura Station',
  'Dickson Station',
  'Belconnen Station',
  'Aranda Station',
  'Woden Station',
  'Calwell Station',
  'Fyshwick Station',
  'Greenway Station',
  'West Belconnen Station',
  'Kambah Station',
  'Fairbairn',
  'Pialligo Station',
  'Tuggeranong Station',
  'Depot',
  'Office',
  'Parks Athlon Drive Depot',
  'Parks Cotter Depot',
  'Parks Mitchell Depot',
  'Parks Namadji',
  'Parks Tidbinbilla',
  'Toll Southcare',
  'Hall Station',
  'Parks Googong',
  'Forrest Fire Station',
  'Canberra Airport'
].sort()
